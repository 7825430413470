<!-- eslint-disable vue/no-mutating-props -->
<template>
  <validation-provider
    v-slot="validationContext"
    :name="formField.label"
    :rules="validationRules"
  >
    <b-form-group
      v-if="!formField.dependency || entity[formField.dependency]"
      :class="(formField.required ? 'required-field' : '') +
        (formField.extraClass ? ' ' + formField.extraClass : '')
        "
      :description="formField.helpText ? formField.helpText : ''"
      :state="getValidationState(validationContext)"
    >
      <slot name="label">
        <label
          class="field-label"
          :for="fieldId"
        >{{ formField.label }}</label>

        <feather-icon
          v-if="formField.layoutPreview"
          icon="LayoutIcon"
          class="cursor-pointer ml-25"
          size="16"
          @click="toggleLayoutPreview(formField.layoutPreview)"
        />
      </slot>
      <input
        v-if="formField.type === 'hidden'"
        :id="fieldId"
        :name="fieldId"
        :value="entity[formField.id]"
        type="hidden"
      />
      <b-input-group
        v-else-if="formField.type === 'text' ||
          formField.type === 'email' ||
          formField.type === 'number'
        "
        :append="formField.suffix"
      >
        <b-form-input
          :id="fieldId"
          v-model="entity[formField.id]"
          :name="fieldId"
          :type="formField.type"
          :autofocus="autofocus"
          :readonly="formField.readonly"
          :step="formField.step"
          :class="formField.readonly ? 'form-control-plaintext' : ''"
          :placeholder="formField.readonly
            ? ''
            : formField.placeholder ? formField.placeholder : formField.label + ' ' + config.title.single
            "
          trim
        />
      </b-input-group>
      <quill-editor
        v-else-if="formField.type === 'quill'"
        :id="fieldId"
        v-model="entity[formField.id]"
        :options="quillOptions"
        class="mb-5 pb-3 quill-editor"
      />
      <v-select
        v-else-if="formField.type === 'select' || formField.type === 'selectmultiple'
        "
        v-model="entity[formField.id]"
        :input-id="fieldId"
        :multiple="formField.type === 'selectmultiple'"
        :close-on-select="formField.type === 'select'"
        :options="selectOptions"
        :reduce="val =>
          formField.type === 'select' && !formField.object && val.id
            ? val.id
            : val
          "
        :append-to-body="appendToBody"
        :taggable="formField.taggable"
        :push-tags="formField.pushTags"
        :disabled="formField.readonly"
        label="title"
        filterable
        @input="dropdownClick(formField.id), fieldUpdated()"
        @search="fetchOptions"
      />
      <b-form-radio-group
        v-else-if="formField.type === 'radio'"
        v-model="entity[formField.id]"
        :options="formField.options"
        :disabled="formField.readonly"
      />
      <flat-pickr
        v-else-if="formField.type === 'date' ||
          formField.type === 'datetime' ||
          formField.type === 'time'
        "
        :id="fieldId"
        v-model="entity[formField.id]"
        :config="datepickerOptions"
        :disabled="formField.readonly"
        class="form-control"
        @on-open="updateDatePickerConfig"
      />
      <b-form-textarea
        v-else-if="formField.type === 'textarea'"
        :id="fieldId"
        v-model="entity[formField.id]"
        :rows="formField.rows ? formField.rows : 2"
        :placeholder="formField.readonly ? '' : formField.label + ' ' + config.title.single
          "
        :class="formField.readonly ? 'form-control-plaintext' : ''"
        trim
      />
      <b-form-checkbox
        v-else-if="formField.type === 'checkbox'"
        :id="fieldId"
        v-model="entity[formField.id]"
        :placeholder="formField.label + ' ' + config.title.single"
        :inline="formField.inline"
        switch
        @change="checkboxClick(formField.id)"
      />
      <b-form-checkbox-group
        v-else-if="formField.type === 'checkboxgroup'"
        :id="fieldId"
        v-model="entity[formField.id]"
        :options="formField.options"
        :size="formField.size || 'md'"
        :required="formField.required"
        :disabled="formField.optionsCnt && entity[formField.id] && entity[formField.id].length >= formField.optionsCnt"
        value-field="id"
        text-field="title"
        stacked
      />
      <b-input-group v-else-if="formField.type === 'password'">
        <b-form-input
          :id="fieldId"
          v-model="entity[formField.id]"
          :type="passwordFieldType"
          :placeholder="formField.label"
          trim
        />
        <b-input-group-append
          v-if="!formField.hideToggle"
          is-text
        >
          <feather-icon
            :icon="passwordToggleIcon"
            class="cursor-pointer"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
      <b-input-group
        v-else-if="formField.type === 'pdf'"
        :append="formField.suffix"
      >
        <div class="d-flex flex-wrap flex-column">
          <a
            v-if="hasFile && entity[formField.id]"
            :href="entity[formField.id]"
            target="_blank"
          >
            {{ $t('Preview') }}
          </a>
          <div class="button-wrap">
            <b-button
              variant="primary"
              @click="$refs.pdfFile.click()"
            >
              <input
                :id="fieldId"
                ref="pdfFile"
                :disabled="formField.readonly"
                type="file"
                accept="application/pdf"
                class="d-none"
                @input="handleFileChange($refs.pdfFile, formField)"
              />
              <span>{{
                $t(`${entity[formField.id] ? 'Update' : 'Add'}`)
              }}</span>
            </b-button>
            <b-button
              v-if="hasFile && entity[formField.id] && !formField.required"
              variant="outline-secondary"
              class="ml-1"
              @click="handleFileChange(null, formField)"
            >
              <span>{{ $t('Delete') }}</span>
            </b-button>
          </div>
        </div>
      </b-input-group>
      <b-media
        v-else-if="formField.type === 'image' || formField.type === 'cropupload'
        "
        class="mt-25 mb-2 col-md-8"
      >
        <template #aside>
          <b-avatar
            v-if="formField.type === 'image'"
            :src="entity[formField.id]"
            :text="formField.id === 'avatar' ? avatarText(entity.name) : ''"
            :size="formField.size ? formField.size : 198"
            rounded
          >
            <feather-icon
              v-if="!entity[formField.id]"
              :icon="formField.icon ? formField.icon : 'ImageIcon'"
              size="110"
            />
          </b-avatar>
          <div v-else-if="formField.type === 'cropupload'">
            <crop-upload
              v-model="modalShow"
              :width="formField.width"
              :height="formField.height"
              :params="{
                entityId: entity.id ? entity.id : 0,
                field: formField.id,
              }"
              :headers="uploadHeaders"
              :url="uploadPath"
              :img-format="formField.imgFormat ? formField.imgFormat : 'jpg'"
              :lang-ext="{
                hint: $t('Click or drag the file here to upload'),
                loading: $t('Uploading') + '...',
                noSupported: $t(
                  'Browser is not supported, please use IE10+ or other browsers',
                ),
                success: $t('Upload success'),
                fail: $t('Upload failed'),
                preview: $t('Preview'),
                btn: {
                  off: $t('Cancel'),
                  close: $t('Close'),
                  back: $t('Back'),
                  save: $t('Save'),
                },
                error: {
                  onlyImg: $t('Image only'),
                  outOfSize: $t('Image exceeds size limit') + ': ',
                  lowestPx:
                    $t('Image size is too low. Expected at least') + ': ',
                },
              }"
              field="file"
              lang-type="en"
              no-circle
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
            />
            <feather-icon
              v-if="!entity[formField.id]"
              :icon="formField.icon ? formField.icon : 'ImageIcon'"
              size="100"
            />
            <img
              :src="entity[formField.id]"
              alt=""
            />
          </div>
        </template>
        <h4
          v-if="formField.id === 'avatar'"
          class="mb-1"
        >
          {{ entity.name }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            v-if="formField.type === 'image'"
            variant="primary"
            @click="$refs.inputFile.click()"
          >
            <input
              :id="fieldId"
              ref="inputFile"
              type="file"
              accept="image/*"
              class="d-none"
              @input="handleImageChange($refs.inputFile, formField)"
            />
            <span>{{ $t(`${entity[formField.id] ? 'Update' : 'Add'}`) }}</span>
          </b-button>
          <b-button
            v-else-if="formField.type === 'cropupload'"
            variant="primary"
            @click="toggleCropModal(formField)"
          >
            <span>{{ $t(`${entity[formField.id] ? 'Update' : 'Add'}`) }}</span>
          </b-button>
          <b-button
            v-if="entity[formField.id] && !formField.required"
            variant="outline-secondary"
            class="ml-1"
            @click="handleImageChange(null, formField)"
          >
            <span>{{ $t('Delete') }}</span>
          </b-button>
        </div>
      </b-media>
      <template v-else-if="formField.type === 'gallery'">
        <div v-if="previewImage">
          <img
            :src="previewImage"
            class="uploader-preview-image"
            alt="previewImage"
            @click="$refs.gallery.preview"
          />
        </div>

        <uploader
          ref="gallery"
          v-model="entity[formField.id]"
          :files="processGalleryFiles(formField.id)"
          :limit-prompt="uploaderLimitPrompt()"
          :auto-upload="false"
          :max-width="4096"
          class="gallery-uploader"
          limit="8"
          @on-change="onUploaderChange($event, formField)"
          @on-delete="onUploaderDelete"
          @on-select-preview-image="onUploaderChangePreviewImage"
        />
      </template>
      <EntityVideoUpload
        v-else-if="formField.type === 'video'"
        :module="module"
        :entity.sync="entity"
        :callback="formField.callback"
      />
      <v-swatches
        v-else-if="formField.type === 'colorpicker'"
        v-model="entity[formField.id]"
        :swatches="swatches"
        row-length="5"
        popover-x="left"
      />

      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import axios from '@/libs/axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider } from 'vee-validate'
import { formUtils } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { required, alphaNum } from '@validations'
import Uploader from 'vux-uploader-component'
import cropUpload from 'vue-image-crop-upload/upload-2.vue'
import EntityVideoUpload from '@/layouts/entity/EntityVideoUpload.vue'
import VSwatches from 'vue-swatches'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  components: {
    flatPickr,
    vSelect,
    quillEditor,
    Uploader,
    cropUpload,
    EntityVideoUpload,
    VSwatches,
    // Form Validation
    ValidationProvider,
  },
  mixins: [formUtils],
  props: {
    module: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    formField: {
      type: Object,
      required: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    checkboxClick: {
      type: Function,
      default: () => { },
      required: false,
    },
    dropdownClick: {
      type: Function,
      default: () => { },
      required: false,
    },
    idSuffix: {
      type: String,
      default: '',
      required: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const { refFormObserver, getValidationState } = formValidation(
      props.clearEntityData,
    )

    const datepickerOptions = {
      allowInput: true,
      time_24hr: true,
      enableTime: true,
      noCalendar: false,
      dateFormat: '',
      defaultDate: null,
      maxDate: null,
      minDate: null,
    }

    const initDatePicker = () => {
      if (props.formField.minDate) {
        datepickerOptions.minDate = props.formField.minDate
      } else if (props.formField.refMinDate) {
        datepickerOptions.minDate = props.entity[props.formField.refMinDate]
      }

      if (props.formField.maxDate) {
        datepickerOptions.maxDate = props.formField.maxDate
      } else if (props.formField.refMaxDate) {
        datepickerOptions.maxDate = props.entity[props.formField.refMaxDate]
      }
      if (props.formField.seconds) {
        datepickerOptions.enableSeconds = true
      }

      switch (props.formField.type) {
        case 'date':
          datepickerOptions.dateFormat = 'Y-m-d'
          datepickerOptions.enableTime = false
          break
        case 'datetime':
          datepickerOptions.dateFormat =
            'Y-m-d H:i' + (props.formField.seconds ? ':s' : '')
          break
        case 'time':
          datepickerOptions.noCalendar = true
          datepickerOptions.dateFormat = 'H:i'
          datepickerOptions.defaultDate = null
          break
        default:
          break
      }
    }

    if (
      props.formField.type === 'date' ||
      props.formField.type === 'datetime' ||
      props.formField.type === 'time'
    ) {
      initDatePicker()
    }

    const updateDatePickerConfig = (selectedDates, dateString, instance) => {
      if (props.formField.refMinDate) {
        datepickerOptions.minDate = props.entity[props.formField.refMinDate]
        instance.set('minDate', props.entity[props.formField.refMinDate])
      } else if (props.formField.refMaxDate) {
        datepickerOptions.maxDate = props.entity[props.formField.refMaxDate]
        instance.set('maxDate', props.entity[props.formField.refMaxDate])
      }
    }

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      datepickerOptions,
      updateDatePickerConfig,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      modalShow: false,
      searchLoading: null,
      hasFile: true,
      options: [],
      swatches: [
        ['#F64272', '#F6648B', '#F493A7', '#F891A6', '#FFCCD5'],
        ['#8b5aff', '#a27bff', '#b99cff', '#d0bdff', '#e8deff'],
        ['#51e5db', '#74ebe3', '#96f0ea', '#b9f5f1', '#dcfaf8'],
        ['#ffa51a', '#ffb748', '#ffc976', '#ffdba3', '#ffedd1'],
      ],
      previewImage: '',
    }
  },
  computed: {
    fieldValue() {
      return this.formField.parent && this.entity[this.formField.parent]
        ? this.entity[this.formField.parent][this.formField.id]
        : this.entity[this.formField.id]
    },
    uploadHeaders() {
      return {
        // eslint-disable-next-line prefer-template
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }
    },
    uploadPath() {
      // eslint-disable-next-line prefer-template
      return process.env.VUE_APP_API_URL + `${this.module}/file-upload`
    },
    validationRules() {
      const rules = []

      if (this.formField.rules) {
        rules.push(this.formField.rules)
      }

      if (this.formField.required && !this.formField.dependency) {
        rules.push('required')
      }

      return rules.join('|')
    },
    autocmplSelect() {
      return this.formField.taggable && this.formField.autocompleteUrl
    },
    config() {
      return this.$store.getters[`${this.module}/config`]
    },
    fieldId() {
      return this.formField.id + this.idSuffix
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    selectOptions() {
      if (this.autocmplSelect) {
        return this.options
      }
      return this.formField.options
    },
    quillOptions() {
      const options = {
        theme: 'snow',
        placeholder: this.formField.placeholderVal
          ? this.entity[`${this.formField.id}placeholder`]
          : `${this.formField.label} ${this.config.title.single}`,
      }

      if (this.formField.limitedOptions) {
        const modules = {
          toolbar: [
            [{ header: '1' }],
            [{ size: [false, 'large'] }],
            ['bold', 'italic'],
          ],
        }

        options.modules = modules
      }

      return options
    },
  },
  mounted() {
    if (this.autocmplSelect && this.formField.options) {
      this.options = this.formField.options
    }
  },
  methods: {
    fieldUpdated() {
      this.$emit('fieldUpdated')
    },
    toggleCropModal() {
      this.modalShow = !this.modalShow
    },
    toggleLayoutPreview(layoutItem) {
      const [layout] = layoutItem.split('_')

      this.$root[layout] = {
        highlight: layoutItem,
        item: this.entity,
      }

      this.$root.$emit('bv::show::modal', `${layout}-layout-preview`)
    },
    uploaderLimitPrompt() {
      return function (limit) {
        // eslint-disable-next-line prefer-template
        return this.$t('No more gallery images allowed! (' + limit + ')')
      }
    },

    async handleImageChange(input, formField) {
      this.processFileChange(input, formField, false)
    },

    async handleFileChange(input, formField) {
      this.processFileChange(input, formField, 'pdf')
    },

    async processFileChange(input, formField, fileType) {
      const file = input && input.files && input.files[0]
      const targetEntity = this.entity[formField.parent]
        ? this.entity[formField.parent]
        : this.entity

      if (file) {
        targetEntity[formField.id] = file
        this.executeAutoupload(targetEntity[formField.id], fileType)
        this.hasFile = true
      } else {
        delete targetEntity[formField.id]
        this.hasFile = false
      }
    },

    async onUploaderChange(e, field) {
      // An image was just deleted, return here
      if (e.deleted) return

      const { id, width, height } = field

      // Remove the element
      // eslint-disable-next-line vue/no-mutating-props
      this.entity[id].splice(this.entity[id].length - 1, 1)

      // --- Get Image Size --- //
      const imageSize = await (async () => {
        const url = URL.createObjectURL(e.blob)
        const img = new Image()
        img.src = url
        await img.decode()
        img.remove()
        URL.revokeObjectURL(e.blob)

        return {
          width: img.width,
          height: img.height,
        }
      })()
      // --- Get Image Size --- //
      if (width !== imageSize.width || height !== imageSize.height) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Please upload an image of the right dimensions'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }

      this.executeAutoupload(e.blob, 'jpg', true)
    },

    onUploaderChangePreviewImage(e, list) {
      if (!list.length) {
        this.previewImage = ''
      } else {
        this.previewImage = list[e].url
      }
    },

    onUploaderDelete(e, cb) {
      e.deleted = true
      this.executeGalleryImgDeletion(e.id)
      cb() // Remove the local image and close previewer
    },

    processGalleryFiles(key) {
      if (this.entity[key]) {
        this.entity[key].forEach(async (item, e) => {
          // eslint-disable-next-line vue/no-mutating-props
          if (item.image) this.entity[key][e].url = item.image
        })
      }
    },
    blobToBase64(blob) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },

    cropUploadSuccess(jsonData) {
      // eslint-disable-next-line vue/no-mutating-props
      this.entity[jsonData.field] = jsonData.file
    },
    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      // eslint-disable-next-line prefer-template
      console.log('field: ' + field)
    },
    executeAutoupload(file, fileType, arrayField) {
      if (this.formField.autoupload) {
        if (file.name.length > 0) {
          const formData = new FormData()
          if (this.entity.id != null) {
            formData.append('entityId', this.entity.id)
          }
          formData.append('file', file)
          formData.append('field', this.formField.id)
          if (fileType) {
            formData.append('type', fileType)
          }
          axios
            .post(`${this.module}/file-upload`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              if (arrayField) {
                // eslint-disable-next-line vue/no-mutating-props
                this.entity[this.formField.id].push({
                  image: response.data.file,
                })
                this.previewImage = response.data.file
              } else {
                // eslint-disable-next-line vue/no-mutating-props
                this.entity[this.formField.id] = response.data.file
              }
              if (response.data.thumbnail) {
                // eslint-disable-next-line vue/no-mutating-props
                this.entity.thumbnail = response.data.thumbnail
              }
              this.$forceUpdate()
            })
        } else {
          this.executeImgDeletion(this.entity.id, this.formField.id)
        }
      }
    },
    executeImgDeletion(id) {
      axios.delete(`${this.module}/img-delete/${id}`)
    },
    executeGalleryImgDeletion(id) {
      axios.delete(`${this.module}/gallery-delete/${id}`)
    },
    fetchOptions(search, loading) {
      if (this.autocmplSelect && search.length && search.length > 2 && !this.searchLoading) {
        loading(true);
        this.searchLoading = setTimeout(() => {
          axios.get(this.formField.autocompleteUrl)
            .then(response => {
              this.options = response.data
              loading(false)
            })
            .catch(() => loading(false))
          this.searchLoading = null
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quill-editor {
  height: 150px;
}

.uploader-preview-image {
  max-width: 720px;
  max-height: 300px;
  width: 100%;
  height: auto;
  cursor: pointer;
}
</style>

<style lang="scss">
.vux-uploader.gallery-uploader {
  padding: 0;

  .vux-uploader_title {
    display: none;
  }

  ul.vux-uploader_files {
    margin: 0;
    padding: 0;
  }
}
</style>
